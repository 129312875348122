// src/components/Dashboard/Dashboard.jsx
import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const Dashboard = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" component="h1" gutterBottom>
          Dashboard
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Total Customers</Typography>
          {/* Add statistics */}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Active Bookings</Typography>
          {/* Add statistics */}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Open Job Cards</Typography>
          {/* Add statistics */}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Pending Invoices</Typography>
          {/* Add statistics */}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
