import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  CircularProgress,
  Alert,
  Snackbar
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  ContentCopy as TemplateIcon
} from '@mui/icons-material';
import { apiService } from '../../services/api';
import ServiceTypeDialog from './ServiceTypeDialog';

const ServiceList = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceTypeDialogOpen, setServiceTypeDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    type: '',
    name: '',
    description: '',
    cost: '',
    duration: '',
    is_template: false
  });


  useEffect(() => {
    loadServices();
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const [servicesResponse, typesResponse] = await Promise.all([
        apiService.getServices(),
        apiService.getServiceTypes()
      ]);
      
      setServices(Array.isArray(servicesResponse.data) ? servicesResponse.data : []);
      
      // Properly handle the service types response
      const types = typesResponse.data?.data || typesResponse.data || [];
      //console.log('Loaded service types:', types); // Debug log
      setServiceTypes(Array.isArray(types) ? types : []);
      
      setError('');
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  };
   // Add handlers for service types
   const handleAddServiceType = async (typeName) => {
    try {
      await apiService.createServiceType({ name: typeName });
      setSnackbar({
        open: true,
        message: 'Service type added successfully',
        severity: 'success'
      });
      loadData();
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Failed to add service type',
        severity: 'error'
      });
    }
  };

  const handleDeleteServiceType = async (typeId) => {
    if (window.confirm('Are you sure? Deleting this type will affect existing services.')) {
      try {
        await apiService.deleteServiceType(typeId);
        setSnackbar({
          open: true,
          message: 'Service type deleted successfully',
          severity: 'success'
        });
        loadData();
      } catch (err) {
        setSnackbar({
          open: true,
          message: 'Failed to delete service type',
          severity: 'error'
        });
      }
    }
  };

  const loadServices = async () => {
    try {
      setLoading(true);
      const response = await apiService.getServices();
      setServices(Array.isArray(response.data) ? response.data : []);
      setError('');
    } catch (err) {
      console.error('Error loading services:', err);
      setError('Failed to load services');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (service = null) => {
    if (service) {
      setSelectedService(service);
      setFormData({
        type: service.type,
        name: service.name,
        description: service.description,
        cost: service.cost,
        duration: service.duration,
        is_template: service.is_template
      });
    } else {
      setSelectedService(null);
      setFormData({
        type: '',
        name: '',
        description: '',
        cost: '',
        duration: '',
        is_template: false
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedService(null);
    setFormData({
      type: '',
      name: '',
      description: '',
      cost: '',
      duration: '',
      is_template: false
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedService) {
        await apiService.updateService(selectedService.id, formData);
        setSnackbar({
          open: true,
          message: 'Service updated successfully',
          severity: 'success'
        });
      } else {
        await apiService.createService(formData);
        setSnackbar({
          open: true,
          message: 'Service created successfully',
          severity: 'success'
        });
      }
      handleCloseDialog();
      loadServices();
    } catch (err) {
      console.error('Error saving service:', err);
      setSnackbar({
        open: true,
        message: 'Failed to save service',
        severity: 'error'
      });
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      try {
        await apiService.deleteService(id);
        setSnackbar({
          open: true,
          message: 'Service deleted successfully',
          severity: 'success'
        });
        loadServices();
      } catch (err) {
        console.error('Error deleting service:', err);
        setSnackbar({
          open: true,
          message: 'Failed to delete service',
          severity: 'error'
        });
      }
    }
  };

  const filteredServices = services.filter(service =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box>
    <Box sx={{ mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Services
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <TextField
          size="small"
          placeholder="Search services..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ flexGrow: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          onClick={() => setServiceTypeDialogOpen(true)}
        >
          Manage Types
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Add Service
        </Button>
      </Box>
    </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Duration (mins)</TableCell>
              <TableCell>Template</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : filteredServices.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No services found
                </TableCell>
              </TableRow>
            ) : (
              filteredServices.map((service) => (
                <TableRow key={service.id}>
                  <TableCell>{service.type}</TableCell>
                  <TableCell>{service.name}</TableCell>
                  <TableCell>{service.description}</TableCell>
                  <TableCell>${service.cost}</TableCell>
                  <TableCell>{service.duration}</TableCell>
                  <TableCell>
                    {service.is_template && (
                      <Chip 
                        icon={<TemplateIcon />} 
                        label="Template" 
                        color="info" 
                        size="small" 
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenDialog(service)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(service.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {selectedService ? 'Edit Service' : 'Add New Service'}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
                  select
                  required
                  label="Type"
                  name="type"
                  value={formData.type}
                  onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                  fullWidth
                >
                  {serviceTypes.map((type) => (
                    <MenuItem key={type.id} value={type.name}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>

              <TextField
                required
                label="Name"
                name="name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                fullWidth
              />

              <TextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                multiline
                rows={3}
                fullWidth
              />

              <TextField
                required
                label="Cost"
                name="cost"
                type="number"
                value={formData.cost}
                onChange={(e) => setFormData({ ...formData, cost: e.target.value })}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                fullWidth
              />

              <TextField
                required
                label="Duration (minutes)"
                name="duration"
                type="number"
                value={formData.duration}
                onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              {selectedService ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

            <ServiceTypeDialog
        open={serviceTypeDialogOpen}
        onClose={() => setServiceTypeDialogOpen(false)}
        serviceTypes={serviceTypes}
        onAdd={handleAddServiceType}
        onDelete={handleDeleteServiceType}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ServiceList;