// src/components/Services/ServiceTemplateManagement.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  CircularProgress,
  Alert,
  Autocomplete,
  Stack,
  Fab
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as ViewIcon
} from '@mui/icons-material';
import { apiService } from '../../services/api';
import { ServiceDialog } from './ServiceCreationDialog';

const ServiceTemplateManagement = () => {
  const [templates, setTemplates] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    services: []
  });
  const [newServiceDialogOpen, setNewServiceDialogOpen] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      setError('');

      const [servicesResponse, templatesResponse] = await Promise.all([
        apiService.getServices(),
        apiService.getServiceTemplates()
      ]);

      const servicesData = Array.isArray(servicesResponse.data) ? servicesResponse.data :
                          servicesResponse.data?.data ? servicesResponse.data.data : [];
      setServices(servicesData);

      const templatesData = Array.isArray(templatesResponse.data) ? templatesResponse.data :
                           templatesResponse.data?.data ? templatesResponse.data.data : [];
      
      const processedTemplates = templatesData.map(template => ({
        ...template,
        services: template.services || []
      }));
      
      setTemplates(processedTemplates);
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (template = null, view = false) => {
    if (template) {
      setSelectedTemplate(template);
      setFormData({
        name: template.name,
        description: template.description || '',
        services: Array.isArray(template.services) ? template.services.map(s => ({
          service: services.find(srv => srv.id === s.service_id) || null,
          modified_cost: s.modified_cost,
          modified_duration: s.modified_duration
        })) : []
      });
    } else {
      setSelectedTemplate(null);
      setFormData({
        name: '',
        description: '',
        services: []
      });
    }
    setViewMode(view);
    setOpenDialog(true);
  };

  const handleCreateService = async (serviceData) => {
    try {
      const response = await apiService.createService({
        name: serviceData.name,
        duration: parseInt(serviceData.duration),
        cost: parseFloat(serviceData.cost),
        description: serviceData.description
      });

      const newService = response.data.data;
      setServices([...services, newService]);

      setFormData({
        ...formData,
        services: [...formData.services, {
          service: newService,
          modified_cost: newService.cost,
          modified_duration: newService.duration
        }]
      });

    } catch (error) {
      console.error('Error creating service:', error);
      setError('Failed to create service');
    }
  };

  const handleAddService = () => {
    setFormData({
      ...formData,
      services: [...formData.services, { service: null, modified_cost: null, modified_duration: null }]
    });
  };

  const handleServiceChange = (index, field, value) => {
    const newServices = [...formData.services];
    newServices[index] = {
      ...newServices[index],
      [field]: value
    };
    setFormData({
      ...formData,
      services: newServices
    });
  };

  const handleRemoveService = (index) => {
    const newServices = formData.services.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      services: newServices
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const templateData = {
        ...formData,
        services: formData.services
          .filter(s => s.service)
          .map(s => ({
            service_id: s.service.id,
            modified_cost: s.modified_cost || s.service.cost,
            modified_duration: s.modified_duration || s.service.duration
          }))
      };

      if (selectedTemplate) {
        await apiService.updateServiceTemplate(selectedTemplate.id, templateData);
      } else {
        await apiService.createServiceTemplate(templateData);
      }

      setOpenDialog(false);
      loadData();
    } catch (err) {
      console.error('Error saving template:', err);
      setError('Failed to save template');
    }
  };

  const calculateTotalCost = (template) => {
    if (!template.services || !Array.isArray(template.services)) return 0;
    return template.services.reduce((sum, s) => sum + (parseFloat(s.modified_cost) || 0), 0);
  };

  const calculateTotalDuration = (template) => {
    if (!template.services || !Array.isArray(template.services)) return 0;
    return template.services.reduce((sum, s) => sum + (parseInt(s.modified_duration) || 0), 0);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Service Templates</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Create Template
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Template Name</TableCell>
              <TableCell>Services Count</TableCell>
              <TableCell>Total Cost</TableCell>
              <TableCell>Total Duration</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No templates found
                </TableCell>
              </TableRow>
            ) : (
              templates.map((template) => (
                <TableRow key={template.id}>
                  <TableCell>{template.name}</TableCell>
                  <TableCell>{(template.services || []).length}</TableCell>
                  <TableCell>${calculateTotalCost(template).toFixed(2)}</TableCell>
                  <TableCell>{calculateTotalDuration(template)} min</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenDialog(template, true)}>
                      <ViewIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDialog(template, false)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="error">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {viewMode ? 'View Template' : selectedTemplate ? 'Edit Template' : 'Create Template'}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={3}>
              <TextField
                label="Template Name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
                disabled={viewMode}
              />

              <TextField
                label="Description"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                multiline
                rows={2}
                disabled={viewMode}
              />

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography variant="subtitle1">Services</Typography>
                {!viewMode && (
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => setNewServiceDialogOpen(true)}
                    sx={{ ml: 2 }}
                  >
                    <AddIcon />
                  </Fab>
                )}
              </Box>

              {formData.services.map((serviceItem, index) => (
                <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                  <Autocomplete
                    options={services}
                    getOptionLabel={(option) => option ? option.name : ''}
                    value={serviceItem.service}
                    onChange={(_, newValue) => handleServiceChange(index, 'service', newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Service" required />
                    )}
                    sx={{ flexGrow: 1 }}
                    disabled={viewMode}
                  />
                  <TextField
                    label="Modified Cost"
                    type="number"
                    value={serviceItem.modified_cost || ''}
                    onChange={(e) => handleServiceChange(index, 'modified_cost', e.target.value)}
                    disabled={viewMode}
                  />
                  <TextField
                    label="Modified Duration"
                    type="number"
                    value={serviceItem.modified_duration || ''}
                    onChange={(e) => handleServiceChange(index, 'modified_duration', e.target.value)}
                    disabled={viewMode}
                  />
                  {!viewMode && (
                    <IconButton color="error" onClick={() => handleRemoveService(index)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              ))}

              {!viewMode && (
                <Button startIcon={<AddIcon />} onClick={handleAddService}>
                  Add Service Line
                </Button>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>
              {viewMode ? 'Close' : 'Cancel'}
            </Button>
            {!viewMode && (
              <Button type="submit" variant="contained" color="primary">
                {selectedTemplate ? 'Update' : 'Create'}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>

      <ServiceDialog
        open={newServiceDialogOpen}
        onClose={() => setNewServiceDialogOpen(false)}
        onSave={handleCreateService}
      />
    </Box>
  );
};

export default ServiceTemplateManagement;