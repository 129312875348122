// File: src/components/Vehicles/VehicleProfile.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiService } from '../../services/api';
import { Paper, Typography } from '@mui/material';

const VehicleProfile = () => {
    const { id } = useParams();
    const [vehicle, setVehicle] = useState(null);

    useEffect(() => {
        const fetchVehicle = async () => {
            const response = await apiService.getVehicle(id);
            setVehicle(response.data);
        };
        fetchVehicle();
    }, [id]);

    if (!vehicle) return <div>Loading...</div>;

    return (
        <Paper>
            <Typography variant="h4">Vehicle Profile</Typography>
            <Typography>Rego: {vehicle.rego}</Typography>
            <Typography>Make: {vehicle.make}</Typography>
            <Typography>Model: {vehicle.model}</Typography>
            <Typography>Year: {vehicle.year}</Typography>
        </Paper>
    );
};

export default VehicleProfile;
