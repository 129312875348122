// src/components/Vehicles/VehicleForm.jsx
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TextField,
  Button,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { apiService } from '../../services/api';

const VehicleForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const customerId = location.state?.customerId;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    rego: '',
    make: '',
    model: '',
    year: '',
    type: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

// src/components/Vehicles/VehicleForm.jsx
const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  setError('');

  try {
      // Create vehicle
      const vehicleResponse = await apiService.createVehicle(formData);
      console.log('Vehicle Response:', vehicleResponse);
      
      const vehicleId = vehicleResponse.id;  // Direct access to nested id
        
      console.log('Extracted Vehicle ID:', vehicleId);
      
      if (customerId && vehicleId) {
          const ownershipData = {
              vehicle_id: parseInt(vehicleId),
              customer_id: parseInt(customerId),
              start_date: new Date().toISOString().split('T')[0]
          };
          
          console.log('Creating ownership with:', ownershipData);
          
          try {
              const ownershipResponse = await apiService.addOwnership(ownershipData);
              console.log('Ownership Response:', ownershipResponse);
          } catch (ownershipError) {
              console.error('Ownership creation failed:', ownershipError);
              throw ownershipError;
          }
      } else {
          console.error('Missing data for ownership:', { 
              customerId: customerId, 
              vehicleId: vehicleId,
              fullResponse: vehicleResponse 
          });
      }

      // Navigate back to customer profile
      navigate(`/customers/${customerId}/profile`);
  } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError('Failed to save vehicle and ownership. Please try again.');
  } finally {
      setLoading(false);
  }
};

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Add New Vehicle {customerId ? `for Customer #${customerId}` : ''}
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            required
            name="rego"
            label="Registration Number"
            value={formData.rego}
            onChange={handleChange}
            fullWidth
          />

          <TextField
            required
            name="make"
            label="Make"
            value={formData.make}
            onChange={handleChange}
            fullWidth
          />

          <TextField
            required
            name="model"
            label="Model"
            value={formData.model}
            onChange={handleChange}
            fullWidth
          />

          <TextField
            name="year"
            label="Year"
            type="number"
            value={formData.year}
            onChange={handleChange}
            fullWidth
          />

          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={formData.type}
              onChange={handleChange}
              label="Type"
            >
              <MenuItem value="ICE">ICE</MenuItem>
              <MenuItem value="Hybrid">Hybrid</MenuItem>
              <MenuItem value="EV">EV</MenuItem>
              <MenuItem value="PHEV">PHEV</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
            <Button
              onClick={() => navigate(customerId ? `/customers/${customerId}/profile` : '/vehicles')}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Save Vehicle'}
            </Button>
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default VehicleForm;