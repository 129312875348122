// src/services/api.js
import axios from 'axios';

const API_BASE_URL = 'https://vrapi.crmflow.co/public';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Request interceptor - Add auth token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor - Handle auth errors
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
      return Promise.reject(new Error('Session expired. Please login again.'));
    }
    return Promise.reject(error);
  }
);

export const apiService = {
  // Auth
  login: async (credentials) => {
    try {
      const response = await api.post('/auth/login', credentials);
      if (response.data?.token) {
        localStorage.setItem('token', response.data.token);
      }
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },


  logout: () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  },

  // Customers
  getCustomers: async () => {
    try {
      const response = await api.get('/data/customers');
      return {
        data: Array.isArray(response.data) ? response.data : 
              response.data?.data ? response.data.data : []
      };
    } catch (error) {
      console.error('Error fetching customers:', error);
      throw error;
    }
  },

  getVehicles: async () => api.get('/data/vehicles'),
  getVehicle: async (id) => api.get(`/data/vehicles/${id}`),
  createVehicle: async (data) => api.post('/data/vehicles', data),
  updateVehicle: async (id, data) => api.put(`/data/vehicles/${id}`, data),
  deleteVehicle: async (id) => api.delete(`/data/vehicles/${id}`),

  getCustomer: async (id) => {
    try {
      const response = await api.get(`/data/customers/${id}`);
      if (response.data?.data) {
        return { data: response.data.data };
      } else if (response.data) {
        return { data: response.data };
      }
      return { data: {} };
    } catch (error) {
      console.error('Error fetching customer:', error);
      throw error;
    }
  },

  createCustomer: async (data) => {
    try {
      const response = await api.post('/data/customers', data);
      return response.data;
    } catch (error) {
      console.error('Error creating customer:', error);
      throw error;
    }
  },

  updateCustomer: async (id, data) => {
    try {
      const response = await api.put(`/data/customers/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Error updating customer:', error);
      throw error;
    }
  },

  deleteCustomer: async (id) => {
    try {
      const response = await api.delete(`/data/customers/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting customer:', error);
      throw error;
    }
  },

  // Vehicles
  getVehicles: async () => {
    try {
      const response = await api.get('/data/vehicles');
      return {
        data: Array.isArray(response.data) ? response.data : 
              response.data?.data ? response.data.data : []
      };
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      throw error;
    }
  },

  getVehicle: async (id) => {
    try {
      const response = await api.get(`/data/vehicles/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching vehicle:', error);
      throw error;
    }
  },

  createVehicle: async (data) => {
    try {
      const response = await api.post('/data/vehicles', data);
      return response.data;
    } catch (error) {
      console.error('Error creating vehicle:', error);
      throw error;
    }
  },

  updateVehicle: async (id, data) => {
    try {
      const response = await api.put(`/data/vehicles/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Error updating vehicle:', error);
      throw error;
    }
  },

  deleteVehicle: async (id) => {
    try {
      const response = await api.delete(`/data/vehicles/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting vehicle:', error);
      throw error;
    }
  },

  // Bookings
  getBookings: async () => {
    try {
      const response = await api.get('/data/bookings');
      return {
        data: Array.isArray(response.data) ? response.data : 
              response.data?.data ? response.data.data : []
      };
    } catch (error) {
      console.error('Error fetching bookings:', error);
      throw error;
    }
  },

  getBooking: async (id) => {
    try {
      const response = await api.get(`/data/bookings/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching booking:', error);
      throw error;
    }
  },

  createBooking: async (data) => {
    try {
      const response = await api.post('/data/bookings', data);
      return response.data;
    } catch (error) {
      console.error('Error creating booking:', error);
      throw error;
    }
  },

  updateBooking: async (id, data) => {
    try {
      const response = await api.put(`/data/bookings/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Error updating booking:', error);
      throw error;
    }
  },
  getCustomerVehicles: async (customerId) => api.get(`/data/ownership/customer/${customerId}`),
  getCustomerBookings: async (customerId) => api.get(`/data/bookings/customer/${customerId}`),
  getCustomerJobCards: async (customerId) => api.get(`/data/jobcards/customer/${customerId}`),
  getCustomerInvoices: async (customerId) => api.get(`/data/invoices/customer/${customerId}`),

  deleteBooking: async (id) => {
    try {
      const response = await api.delete(`/data/bookings/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting booking:', error);
      throw error;
    }
  },

  addOwnership: async (data) => {
    try {
      console.log('Sending ownership data:', data);
      const response = await api.post('/data/ownership', data);
      console.log('Ownership creation response:', response);
      return response;
    } catch (error) {
      console.error('Error creating ownership:', error);
      throw error;
    }
  },
  // Add these methods to your existing apiService object in api.js

// Services
getServices: async () => {
  try {
    const response = await api.get('/data/services');
    return {
      data: Array.isArray(response.data) ? response.data : 
            response.data?.data ? response.data.data : []
    };
  } catch (error) {
    console.error('Error fetching services:', error);
    throw error;
  }
},

getService: async (id) => {
  try {
    const response = await api.get(`/data/services/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching service:', error);
    throw error;
  }
},

createService: async (data) => {
  try {
    const response = await api.post('/data/services', data);
    return response.data;
  } catch (error) {
    console.error('Error creating service:', error);
    throw error;
  }
},

updateService: async (id, data) => {
  try {
    const response = await api.put(`/data/services/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating service:', error);
    throw error;
  }
},

deleteService: async (id) => {
  try {
    const response = await api.delete(`/data/services/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting service:', error);
    throw error;
  }
},

// Service Templates
getServiceTemplates: async () => {
  try {
    const response = await api.get('/data/services?type=Template');
    return {
      data: Array.isArray(response.data) ? response.data : 
            response.data?.data ? response.data.data : []
    };
  } catch (error) {
    console.error('Error fetching service templates:', error);
    throw error;
  }
},
// Add to your apiService

// Service Templates
getServiceTemplates: async () => {
  try {
    const response = await api.get('/data/service-templates');
    return {
      data: Array.isArray(response.data) ? response.data : 
            response.data?.data ? response.data.data : []
    };
  } catch (error) {
    console.error('Error fetching service templates:', error);
    throw error;
  }
},

getServiceTemplate: async (id) => {
  try {
    const response = await api.get(`/data/service-templates/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching service template:', error);
    throw error;
  }
},

createServiceTemplate: async (data) => {
  try {
    console.log('Creating template with data:', data); // Debug log
    const response = await api.post('/data/service-templates', {
      name: data.name,
      description: data.description,
      services: data.services.map(service => ({
        service_id: service.service_id,
        modified_cost: service.modified_cost || null,
        modified_duration: service.modified_duration || null
      }))
    });
    return response.data;
  } catch (error) {
    console.error('Error creating service template:', error);
    throw error;
  }
},

updateServiceTemplate: async (id, data) => {
  try {
    console.log('Updating template with data:', data); // Debug log
    const response = await api.put(`/data/service-templates/${id}`, {
      name: data.name,
      description: data.description,
      services: data.services.map(service => ({
        service_id: service.service_id,
        modified_cost: service.modified_cost || null,
        modified_duration: service.modified_duration || null
      }))
    });
    return response.data;
  } catch (error) {
    console.error('Error updating service template:', error);
    throw error;
  }
},

deleteServiceTemplate: async (id) => {
  try {
    const response = await api.delete(`/data/service-templates/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting service template:', error);
    throw error;
  }
},
getServiceTypes: async () => {
  try {
    const response = await api.get('/data/service_types');
    return response;
  } catch (error) {
    console.error('Error fetching service types:', error);
    throw error;
  }
},

createServiceType: async (data) => {
  try {
    const response = await api.post('/data/service_types', data);
    return response;
  } catch (error) {
    console.error('Error creating service type:', error);
    throw error;
  }
},

deleteServiceType: async (id) => {
  try {
    const response = await api.delete(`/data/service_types/${id}`);
    return response;
  } catch (error) {
    console.error('Error deleting service type:', error);
    throw error;
  }
}
};

export default apiService;