// src/components/Customers/CustomerProfile.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Tab,
  Tabs,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Chip,
  Divider,
  Avatar,
  Stack,
  IconButton,
} from '@mui/material';
import {
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  DirectionsCar as CarIcon,
  Schedule as ScheduleIcon,
  Build as BuildIcon,
  Receipt as ReceiptIcon,
  Add as AddIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Home as HomeIcon,
  CalendarToday as CalendarIcon,
} from '@mui/icons-material';
import { apiService } from '../../services/api';

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const InfoItem = ({ icon, label, value }) => (
  <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
    {icon}
    <Typography variant="body2" color="text.secondary">{label}:</Typography>
    <Typography variant="body1">{value || '-'}</Typography>
  </Stack>
);

const EmptyState = ({ icon: Icon, message, actionButton }) => (
  <Box sx={{ 
    textAlign: 'center', 
    py: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2 
  }}>
    <Icon sx={{ fontSize: 48, color: 'text.disabled' }} />
    <Typography color="text.secondary" variant="h6">
      {message}
    </Typography>
    {actionButton}
  </Box>
);

const CustomerProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [customerData, setCustomerData] = useState({
    customer: null,
    vehicles: [],
    bookings: [],
    jobCards: [],
    invoices: [],
  });

  useEffect(() => {
    loadCustomerData();
  }, [id, location.key]);  // Replace the old dependency array

  const loadCustomerData = async () => {
    setLoading(true);
    setError('');

    try {
      const customerResponse = await apiService.getCustomer(id);
      const customer = customerResponse.data;
      const data = {
        customer,
        vehicles: [],
        bookings: [],
        jobCards: [],
        invoices: [],
      };

      try {
        const vehiclesResponse = await apiService.getCustomerVehicles(id);
        data.vehicles = vehiclesResponse.data || [];
      } catch (err) {
        console.log('No vehicles found:', err);
      }

      try {
        const bookingsResponse = await apiService.getCustomerBookings(id);
        data.bookings = bookingsResponse.data || [];
      } catch (err) {
        console.log('No bookings found:', err);
      }

      try {
        const jobCardsResponse = await apiService.getCustomerJobCards(id);
        data.jobCards = jobCardsResponse.data || [];
      } catch (err) {
        console.log('No job cards found:', err);
      }

      try {
        const invoicesResponse = await apiService.getCustomerInvoices(id);
        data.invoices = invoicesResponse.data || [];
      } catch (err) {
        console.log('No invoices found:', err);
      }

      setCustomerData(data);
      setError('');
    } catch (err) {
      console.error('Error loading customer data:', err);
      setError('Failed to load customer information.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="500px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button startIcon={<ArrowBackIcon />} onClick={() => navigate('/customers')}>
          Back to Customers
        </Button>
      </Box>
    );
  }

  const { customer, vehicles, bookings, jobCards, invoices } = customerData;

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto'}}>
      {/* Customer Overview Card */}
      <Card sx={{ mb: 3, position: 'relative' }}>
       
        <CardContent sx={{ position: 'relative', pt: 2, bgcolor: '#d1fcc5' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>

            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1,  }}>
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    bgcolor: '#FF6363',
                    fontSize: '2rem'
                  }}
                >
                  {customer?.name?.charAt(0) || 'C'}
                </Avatar>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    {customer?.name}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Chip icon={<CarIcon />} label={`${vehicles.length} Vehicles`} />
                    <Chip icon={<BuildIcon />} label={`${jobCards.length} Jobs`} />
                    <Chip icon={<ReceiptIcon />} label={`${invoices.length} Invoices`} />
                    <Chip icon={<EditIcon />} label={`Edit`} onClick={() => navigate(`/customers/${customer.id}`)} />

                  </Stack>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Card variant="outlined">
            
                <CardContent>
                  <InfoItem icon={<PhoneIcon color="primary" />} label="Phone" value={customer?.phone} />

                  <InfoItem icon={<PhoneIcon color="primary" />} label="Mobile" value={customer?.mobile} />
                  <InfoItem icon={<EmailIcon color="primary" />} label="Email" value={customer?.email} />
                  <InfoItem icon={<HomeIcon color="primary" />} label="Address" value={customer?.address} />
                </CardContent>

              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Tabs Section */}
      <Card>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant="fullWidth"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab icon={<CarIcon />} label="Vehicles" />
          <Tab icon={<ScheduleIcon />} label="Bookings" />
          <Tab icon={<BuildIcon />} label="Job Cards" />
          <Tab icon={<ReceiptIcon />} label="Invoices" />
        </Tabs>

        {/* Vehicles Tab */}
        <TabPanel value={activeTab} index={0}>


          {vehicles.length === 0 ? (
            <EmptyState
              icon={CarIcon}
              message="No vehicles found for this customer"

            />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Rego</TableCell>
                    <TableCell>Make</TableCell>
                    <TableCell>Model</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Year</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehicles.map((vehicle) => (
                    <TableRow
                      key={vehicle.id}
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/vehicles/${vehicle.id}`)}
                    >
                      <TableCell>{vehicle.rego || '-'}</TableCell>
                      <TableCell>{vehicle.make || '-'}</TableCell>
                      <TableCell>{vehicle.model || '-'}</TableCell>
                      <TableCell>
                        <Chip
                          label={vehicle.type || 'N/A'}
                          size="small"
                          color={vehicle.type ? 'primary' : 'default'}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>{vehicle.year || '-'}</TableCell>
                      <TableCell>
                        <Chip label="Active" size="small" color="success" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                    <Button
    variant="outlined"
    color="primary"
    startIcon={<AddIcon />}
    onClick={() => navigate('/vehicles/new', { state: { customerId: id } })}
>
    Add Vehicle
</Button>
          </Box>
        </TabPanel>

        {/* Bookings Tab */}
        <TabPanel value={activeTab} index={1}>
          {bookings.length === 0 ? (
            <EmptyState
              icon={ScheduleIcon}
              message="No bookings found for this customer"
              actionButton={
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/bookings/new')}
                >
                  Create Booking
                </Button>
              }
            />
          ) : (
            <TableContainer>
              {/* Add your bookings table content here */}
            </TableContainer>
          )}
        </TabPanel>

        {/* Job Cards Tab */}
        <TabPanel value={activeTab} index={2}>
          {jobCards.length === 0 ? (
            <EmptyState
              icon={BuildIcon}
              message="No job cards found for this customer"
              actionButton={
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/job-cards/new')}
                >
                  Create Job Card
                </Button>
              }
            />
          ) : (
            <TableContainer>
              {/* Add your job cards table content here */}
            </TableContainer>
          )}
        </TabPanel>

        {/* Invoices Tab */}
        <TabPanel value={activeTab} index={3}>
          {invoices.length === 0 ? (
            <EmptyState
              icon={ReceiptIcon}
              message="No invoices found for this customer"
            />
          ) : (
            <TableContainer>
              {/* Add your invoices table content here */}
            </TableContainer>
          )}
        </TabPanel>
      </Card>
    </Box>
  );
};

export default CustomerProfile;