import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  CircularProgress
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { apiService } from '../../services/api';

const ServiceTypeDialog = ({ open, onClose }) => {
  const [newType, setNewType] = useState('');
  const [error, setError] = useState('');
  const [types, setTypes] = useState([]); // Initialize as empty array
  const [loading, setLoading] = useState(false);

  const loadTypes = async () => {
    try {
      setLoading(true);
      const response = await apiService.getServiceTypes();
      console.log('Service Types Response:', response);
      
      // Ensure we're setting an array
      const typeData = response?.data?.data || response?.data || [];
      setTypes(Array.isArray(typeData) ? typeData : []);
      
    } catch (err) {
      console.error('Error loading types:', err);
      setError('Failed to load service types');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      loadTypes();
    }
  }, [open]);

  const handleAdd = async () => {
    try {
      if (!newType.trim()) {
        setError('Type name is required');
        return;
      }

      setLoading(true);
      await apiService.createServiceType({ name: newType.trim() });
      setNewType('');
      await loadTypes(); // Reload the list
    } catch (err) {
      console.error('Error adding type:', err);
      setError('Failed to add type');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await apiService.deleteServiceType(id);
      await loadTypes(); // Reload the list
    } catch (err) {
      console.error('Error deleting type:', err);
      setError('Failed to delete type');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Manage Service Types</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2, mt: 1 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
              fullWidth
              size="small"
              label="New Service Type"
              value={newType}
              onChange={(e) => setNewType(e.target.value)}
              error={!!error}
              helperText={error}
              disabled={loading}
            />
            <Button
              variant="contained"
              onClick={handleAdd}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Add'}
            </Button>
          </Box>
        </Box>
        
        {loading ? (
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {types.length > 0 ? (
              types.map((type) => (
                <ListItem key={type.id} divider>
                  <ListItemText primary={type.name} />
                  <ListItemSecondaryAction>
                    <IconButton 
                      edge="end" 
                      color="error"
                      onClick={() => handleDelete(type.id)}
                      disabled={loading}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No service types found" />
              </ListItem>
            )}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceTypeDialog;