// src/components/Customers/CustomerForm.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Alert,
  CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { apiService } from '../../services/api';

const CustomerForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [customer, setCustomer] = useState({
    name: '',
    address: '',
    phone: '',
    mobile: '',
    email: '',
  });

  useEffect(() => {
    if (id) {
      loadCustomer();
    }
  }, [id]);

  const loadCustomer = async () => {
    try {
      setLoading(true);
      setError('');
      console.log('Loading customer with ID:', id); // Debug log
      const response = await apiService.getCustomer(id);
      console.log('API Response:', response); // Debug log
      
      // Handle different response structures
      const customerData = response.data?.data || response.data || {};
      
      console.log('Processed customer data:', customerData); // Debug log
      
      setCustomer({
        name: customerData.name || '',
        address: customerData.address || '',
        phone: customerData.phone || '',
        mobile: customerData.mobile || '',
        email: customerData.email || '',
      });
    } catch (err) {
      console.error('Error loading customer:', err);
      setError('Failed to load customer data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        setSaving(true);
        setError('');

        const customerData = {
            name: customer.name,
            address: customer.address || null,
            phone: customer.phone || null,
            mobile: customer.mobile || null,
            email: customer.email || null,
        };

        if (id) {
            await apiService.updateCustomer(id, customerData);
            navigate(`/customers/${id}/profile`); // Navigate to profile when editing
        } else {
            const response = await apiService.createCustomer(customerData);
            navigate('/customers'); // Navigate to list only for new customers
        }
    } catch (err) {
        console.error('Error saving customer:', err);
        setError(err.response?.data?.message || 'Failed to save customer. Please try again.');
    } finally {
        setSaving(false);
    }
};

const handleBack = () => {
  if (id) {
      navigate(`/customers/${id}/profile`);
  } else {
      navigate('/customers');
  }
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomer(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/customers')}
        >
          Back to Customers
        </Button>
        <Typography variant="h5" component="h1">
          {id ? 'Edit Customer' : 'New Customer'}
        </Typography>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              name="name"
              label="Name"
              value={customer.name}
              onChange={handleChange}
              error={!customer.name}
              helperText={!customer.name ? 'Name is required' : ''}
              disabled={saving}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name="address"
              label="Address"
              value={customer.address || ''}
              onChange={handleChange}
              disabled={saving}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="phone"
              label="Phone"
              value={customer.phone || ''}
              onChange={handleChange}
              disabled={saving}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="mobile"
              label="Mobile"
              value={customer.mobile || ''}
              onChange={handleChange}
              disabled={saving}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email"
              value={customer.email || ''}
              onChange={handleChange}
              error={customer.email && !/\S+@\S+\.\S+/.test(customer.email)}
              helperText={
                customer.email && !/\S+@\S+\.\S+/.test(customer.email)
                  ? 'Invalid email format'
                  : ''
              }
              disabled={saving}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                onClick = {handleBack}
                disabled={saving}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={saving}
                sx={{ minWidth: 100 }}
              >
                {saving ? (
                  <>
                    <CircularProgress size={20} sx={{ mr: 1 }} />
                    Saving...
                  </>
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default CustomerForm;