// src/components/Services/ServiceCreationDialog.jsx
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
  InputAdornment
} from '@mui/material';

export const ServiceDialog = ({ open, onClose, onSave }) => {
  const [serviceData, setServiceData] = useState({
    name: '',
    duration: '',
    cost: '',
    description: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(serviceData);
    setServiceData({
      name: '',
      duration: '',
      cost: '',
      description: ''
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Service</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              required
              label="Service Name"
              value={serviceData.name}
              onChange={(e) => setServiceData({ ...serviceData, name: e.target.value })}
              fullWidth
            />
            <TextField
              required
              label="Duration (minutes)"
              type="number"
              value={serviceData.duration}
              onChange={(e) => setServiceData({ ...serviceData, duration: e.target.value })}
              fullWidth
            />
            <TextField
              required
              label="Cost"
              type="number"
              value={serviceData.cost}
              onChange={(e) => setServiceData({ ...serviceData, cost: e.target.value })}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              fullWidth
            />
            <TextField
              label="Description"
              multiline
              rows={3}
              value={serviceData.description}
              onChange={(e) => setServiceData({ ...serviceData, description: e.target.value })}
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Create Service
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
